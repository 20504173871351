exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-health-and-safety-jsx": () => import("./../../../src/pages/health-and-safety.jsx" /* webpackChunkName: "component---src-pages-health-and-safety-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-services-commercial-scaffolding-jsx": () => import("./../../../src/pages/services/commercial-scaffolding.jsx" /* webpackChunkName: "component---src-pages-services-commercial-scaffolding-jsx" */),
  "component---src-pages-services-confined-space-entry-scaffolding-jsx": () => import("./../../../src/pages/services/confined-space-entry-scaffolding.jsx" /* webpackChunkName: "component---src-pages-services-confined-space-entry-scaffolding-jsx" */),
  "component---src-pages-services-fresh-water-scaffolding-jsx": () => import("./../../../src/pages/services/fresh-water-scaffolding.jsx" /* webpackChunkName: "component---src-pages-services-fresh-water-scaffolding-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-services-residential-scaffolding-jsx": () => import("./../../../src/pages/services/residential-scaffolding.jsx" /* webpackChunkName: "component---src-pages-services-residential-scaffolding-jsx" */),
  "component---src-pages-services-scaffolding-design-jsx": () => import("./../../../src/pages/services/scaffolding-design.jsx" /* webpackChunkName: "component---src-pages-services-scaffolding-design-jsx" */),
  "component---src-pages-services-temporary-roofing-jsx": () => import("./../../../src/pages/services/temporary-roofing.jsx" /* webpackChunkName: "component---src-pages-services-temporary-roofing-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

